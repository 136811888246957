import { Suspense } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import useRefs from 'react-use-refs'
import { Canvas } from '@react-three/fiber'

import { isSupportedBrowser } from '../utils/common'

// Zustand store, keeps track on the currently active simulation
import useStorage from '../store/useStore'

// "*Component" - R3F stuff for given simulation
import RobotArmComponent from '../simulations/RobotArm/RobotArmComponent'
import WarehouseComponent from '../simulations/Warehouse/WarehouseComponent'
import ContinuumRobotComponent from '../simulations/ContinuumRobot/ContinuumRobotComponent'

import StyledNavbar from './StyledNavbar'
import Footer from '../components/Footer'

/*
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * The outer layout which would be common across all the components/pages
 * that would be wrapped around by this component should be kept here to
 * avoid redundant code everywhere.
 */

const components = {
  robotArm: RobotArmComponent,
  warehouse: WarehouseComponent,
  continuum: ContinuumRobotComponent
}

const SharedLayout = () => {
  const [ref, myCanvas] = useRefs()

  // Get currentPage string that is set on entering RobotArmPage and WarehousePage.
  // currentPage determines which View is active on the Canvas
  const currentPage = useStorage((state) => state.currentPage)

  const CurrentComponent = components[currentPage]

  return (
    <>
      {!isSupportedBrowser() ? (
        <Navigate replace to="/browser-problem" />
      ) : (
        <>
          <div ref={ref} className="container">
            <StyledNavbar />
            <Outlet />
            {CurrentComponent && (
              <>
                <div ref={myCanvas} className={'mycanvas'} />
                <Canvas eventSource={ref} className="canvas">
                  <Suspense fallback={null}>
                    {/**Logic for swapping views, Part 2.**/}
                    {/*One view active at a time. Each component sets a view on the entire screen.*/}
                    <CurrentComponent myview={myCanvas} />

                    {/*<Preload all />*/}
                    {/*/!*QUESTION: Will "Preload all" slow down the moment when */}
                    {/* the user sees the 1st animation (robotArm) on the homepage? */}
                    {/* It would be best to get the 1st animation as soon as possible and only then */}
                    {/* load others in the background. How to do that? */}
                  </Suspense>
                </Canvas>
              </>
            )}
          </div>
          <Footer />
        </>
      )}
    </>
  )
}
export default SharedLayout
