import React, { useRef, useEffect, useState, useMemo, useLayoutEffect } from 'react'
import { Clone, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useRobotArmStore } from '../store/useRobotArmStore'

function mat2js(mat) {
  const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
  return matjs
}

export default function RobotLink({ link_name, priority, module, filepath, setTorque, color}){

  const ref = useRef()
  const [counter, setCounter] = useState(0)
  useGLTF.preload(filepath)
  var model = useGLTF(filepath)

  useEffect(() => {
    // console.log(model)
    model.materials[""].color.r = color[0];
    model.materials[""].color.g = color[1];
    model.materials[""].color.b = color[2];
    // console.log()
    // model.materials.color = {isColor: true, r: 0.5, g: 0.5, b:0.5}

  }, [])

// const clonedScene = useMemo(() => model.clone(), [])
// useLayoutEffect(() => {
//   clonedScene.traverse(o => {
//     if (o.type === 'Mesh') {
//       console.log(o)
//     }
//   })
// }, [clonedScene])

  // useMemo(() => {
  //   console.log(materials)
  //   // materials.someMaterial.color.set("#ff0000")
  //   // materials.someMaterial.transparent = true
  //   // materials.someMaterial.opacity = 0.5
  // }, [materials])

  // useEffect(() => {
  // }, [])


  useFrame(function(state, delta) {
    ref.current.matrix.elements = mat2js(module['getTransformByID'](link_name))

    if(link_name === 'robot_base'){
      const currentGlobalAngleGoal = useRobotArmStore.getState().currentSliderValue
      const currentJ1DistanceGoal = useRobotArmStore.getState().currentJ1TranslationValue
      module['setGlobalAngleGoal'](currentGlobalAngleGoal)
      module['setJ1AngleGoal'](currentJ1DistanceGoal)
      module['animationStep']()
    }

    if(!(counter%5)){
      // console.log(ref0.current.matrix.elements[12])
      setTorque(ref.current.matrix.elements[15]);
      // const torque = getTorque(ref.current.matrix.elements[15]);
    }
    setCounter(counter + 1)

    // console.log(link_name)
    // console.log(counter)

  }, priority)

  return (
    <>
      <Clone
        ref={ref}
        object={model.scene}
        // scale={0.35}
        matrixAutoUpdate={false}
        // position={position}
      />
    </>
  )
}