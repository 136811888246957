import BgVideo from '../components/BgVideo'
import Footer from '../components/Footer'

const DemoVideo = () => {
  return (
    <>
      <BgVideo />
      <Footer />
    </>
  )
}
export default DemoVideo
