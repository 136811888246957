/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, useContext, createContext } from 'react'
import { useGLTF, Merged } from '@react-three/drei'

const context = createContext()
export function Instances({ children, ...props }) {
  const { nodes } = useGLTF('./models/warehouse/robot-body-lowpoly-draco.glb')
  const instances = useMemo(
    () => ({
      Meshid: nodes.mesh_id4003,
    }),
    [nodes]
  )
  return (
    <Merged meshes={instances} {...props}>
      {(instances) => <context.Provider value={instances} children={children} />}
    </Merged>
  )
}

export function RobotBodyLowpoly(props) {
  const instances = useContext(context)
  return (
    <group {...props} dispose={null}>
      <instances.Meshid rotation={[-Math.PI / 2, 0, 0]} scale={0} />
    </group>
  )
}

useGLTF.preload('./models/warehouse/robot-body-lowpoly-draco.glb')
