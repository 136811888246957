import useStorage from '../store/useStore'

export default function Footer() {
  const additionalInfo = useStorage((state) => state.additionalInfo)

  return (<footer style={{ height: '3%' }}>
      <span>
        by&nbsp;
        <a
          href='https://jakub-kaminski.com'
        >
          <b>jakub-kaminski.com</b>
        </a>
      </span>
      {/* Notice how the modelCredit value is retrieved from the zustand store */}
      <span>{additionalInfo}</span>
    </footer>)
}
