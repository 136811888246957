import React, { useRef, useEffect, useState } from 'react'
import { Clone, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

function mat2js(mat) {
  const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
  return matjs
}

export default function LowPolyKiva({robotID, module}){
  const ref_body = useRef()

  const robot_body_model = useGLTF('./models/warehouse/robot-body-lowpoly-draco.glb')

  useFrame(function(state, delta) {
    // if(robotID == 0) module['simulationStep']();
    // module['simulationStep']();
    ref_body.current.matrix.elements = mat2js(module['getRobotTransform'](robotID, 0))

  }, -2)

  return (
    <>
      <Clone
        ref={ref_body}
        object={robot_body_model.scene}
        matrixAutoUpdate={false}
      />
    </>
  )
}
useGLTF.preload('./models/warehouse/robot-body-lowpoly-draco.glb')
