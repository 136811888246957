export default function BgVideo() {
  return (
    <div
      style={{
        position: 'fixed',
        right: 0,
        bottom: 0,
        minWidth: '100%',
        minHeight: '100%'
      }}>
      <iframe
        src="https://iframe.mediadelivery.net/embed/23466/796ff6e1-867c-49c7-9531-e6418e4ad6ac?autoplay=true&loop=true&muted=true"
        loading="lazy"
        style={{
          border: 'none',
          position: 'absolute',
          top: 0,
          height: '100%',
          width: '100%'
        }}
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowFullScreen={true}></iframe>
    </div>
  )
}
