import React, { useRef, useEffect, useState } from 'react'
// import { Clone, useGLTF } from '@react-three/drei'
// import { useFrame } from '@react-three/fiber'

import {useRobotArmStore} from '../store/useRobotArmStore'
import RobotLink from './RobotLink'

// function mat2js(mat) {
//   const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
//   return matjs
// }
const robotColor1 = [0.6,0.6,0.6]
const robotColor2 = [0.2,0.2,0.2]
const colorRed = [1.0,0.0,0.0]

export function VicariousRobot({ module, linkID, position }) {
  const setTorque = useRobotArmStore((state) => state.setTorque)

  return (
    <>
      {/*<RobotLink link_name={"robot_base"} module={module} filepath={'./models/vicarious/warehouse-robot-body.glb'} priority={-1} setTorque={setTorque}/>*/}
      {/*<RobotLink link_name={"robot_base"} module={module} filepath={'./models/vicarious/untitled.glb'} priority={-1} setTorque={setTorque}/>*/}

      <RobotLink link_name={"robot_base"} module={module} filepath={'./models/vicarious/base/robot_base.glb'} priority={-1} setTorque={setTorque} color={robotColor1}/>
      <RobotLink link_name={"base_link_1"} module={module} filepath={'./models/vicarious/base/base_link_1.glb'} priority={-2} setTorque={setTorque} color={robotColor1}/>
      <RobotLink link_name={"base_link_2"} module={module} filepath={'./models/vicarious/base/base_link_2.glb'} priority={-2} setTorque={setTorque} color={robotColor1}/>
      <RobotLink link_name={"base_link_3"} module={module} filepath={'./models/vicarious/base/base_link_3.glb'} priority={-2} setTorque={setTorque} color={robotColor1}/>
      <RobotLink link_name={"base_link_4"} module={module} filepath={'./models/vicarious/base/base_link_4.glb'} priority={-2} setTorque={setTorque} color={robotColor1}/>
      <RobotLink link_name={"base_link_5"} module={module} filepath={'./models/vicarious/base/base_link_5.glb'} priority={-2} setTorque={setTorque} color={robotColor1}/>
      <RobotLink link_name={"base_link_5fixed"} module={module} filepath={'./models/vicarious/base/base_link_5fixed.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>

      <RobotLink link_name={"camera_link_1"} module={module} filepath={'./models/vicarious/camera/camera_link_1.glb'} priority={-2} setTorque={setTorque} color={robotColor1}/>
      <RobotLink link_name={"camera_link_1fixed"} module={module} filepath={'./models/vicarious/camera/camera_link_1fixed.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"camera_link_2"} module={module} filepath={'./models/vicarious/camera/camera_link_2.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"camera_link_3"} module={module} filepath={'./models/vicarious/camera/camera_link_3.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"camera_link_4"} module={module} filepath={'./models/vicarious/camera/camera_link_4.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>

      <RobotLink link_name={"left_arm_link_1"} module={module} filepath={'./models/vicarious/left_arm_link_1.glb'} priority={-2} setTorque={setTorque} color={robotColor1}/>
      <RobotLink link_name={"left_arm_link_1fixed"} module={module} filepath={'./models/vicarious/left_arm_link_1fixed.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"left_arm_link_2"} module={module} filepath={'./models/vicarious/arm_common/arm_link_2.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"left_arm_link_3"} module={module} filepath={'./models/vicarious/arm_common/arm_link_3.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"left_arm_link_4"} module={module} filepath={'./models/vicarious/arm_common/arm_link_4.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"left_arm_link_5"} module={module} filepath={'./models/vicarious/arm_common/arm_link_5.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"left_arm_link_6"} module={module} filepath={'./models/vicarious/arm_common/arm_link_6.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"left_arm_link_7"} module={module} filepath={'./models/vicarious/arm_common/arm_link_7.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"left_arm_link_8"} module={module} filepath={'./models/vicarious/arm_common/arm_link_8.glb'} priority={-2} setTorque={setTorque} color={colorRed}/>

      <RobotLink link_name={"right_arm_link_1"} module={module} filepath={'./models/vicarious/right_arm_link_1.glb'} priority={-2} setTorque={setTorque} color={robotColor1}/>
      <RobotLink link_name={"right_arm_link_1fixed"} module={module} filepath={'./models/vicarious/right_arm_link_1fixed.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"right_arm_link_2"} module={module} filepath={'./models/vicarious/arm_common/arm_link_2.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"right_arm_link_3"} module={module} filepath={'./models/vicarious/arm_common/arm_link_3.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"right_arm_link_4"} module={module} filepath={'./models/vicarious/arm_common/arm_link_4.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"right_arm_link_5"} module={module} filepath={'./models/vicarious/arm_common/arm_link_5.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"right_arm_link_6"} module={module} filepath={'./models/vicarious/arm_common/arm_link_6.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"right_arm_link_7"} module={module} filepath={'./models/vicarious/arm_common/arm_link_7.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"right_arm_link_8"} module={module} filepath={'./models/vicarious/arm_common/arm_link_8.glb'} priority={-2} setTorque={setTorque} color={colorRed}/>

      <RobotLink link_name={"right_arm_link_8a"} module={module} filepath={'./models/vicarious/arm_common/arm_link_8x.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"right_arm_link_8b"} module={module} filepath={'./models/vicarious/arm_common/arm_link_8x.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>

      <RobotLink link_name={"left_arm_link_8a"} module={module} filepath={'./models/vicarious/arm_common/arm_link_8x.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
      <RobotLink link_name={"left_arm_link_8b"} module={module} filepath={'./models/vicarious/arm_common/arm_link_8x.glb'} priority={-2} setTorque={setTorque} color={robotColor2}/>
    </>

  )
}