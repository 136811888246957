import {Link} from 'react-router-dom'
import useStorage from '../store/useStore'

const ErrorPage = () => {
  const setPage = useStorage((state) => state.setPage)
  setPage("error")

    return (
        <section className='section'>
            <h2>404</h2>
            <p>Page not found</p>
            <Link to={"/"}>back home</Link>
        </section>
    );
};
export default ErrorPage;
