import React, { useRef, useEffect, useState } from 'react'
import { Clone, useGLTF } from '@react-three/drei'
// import { useFrame } from '@react-three/fiber'

// function mat2js(mat) {
//   const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
//   return matjs
// }

export default function Floor({ size_x, size_y }) {
  const ref = useRef()
  const model = useGLTF('./models/warehouse/tile.glb')

  // useFrame(function(state, delta) {
  //   ref.current.matrix.elements = mat2js(module['getObjectTransform']("shelf", id))
  //
  //   // console.log(link_name)
  //   // console.log(counter)
  //
  // }, -2)

  return (
    <>
      {[...Array(16*16)].map((x, i) =>
        <Clone
          ref={ref}
          object={model.scene}
          position={[ (i % 16)*0.9, 0, -(Math.floor(i/16))*0.9]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
      )}
    </>
  )
}

useGLTF.preload('./models/warehouse/tile.glb')