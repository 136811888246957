import { useEffect, useRef, useState } from 'react'
import { Environment, OrbitControls, PerspectiveCamera, View } from '@react-three/drei'
import { Perf } from 'r3f-perf'

import createVicarious from './moduleVicarious'
import { VicariousRobot } from './components/VicariousRobot'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { isDesktopScreen, isMobileScreen } from '../../utils/common'

/*
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * We can keep this also inside the components folder of the RobotArm module.
 * Also the parent folder is named simulations, but I think we can have these
 * modules kept under the pages folder itself, since ultimately these are the
 * app pages. But it's a trivial thing so we can leave it as it is too. Matter of
 * preference.
 */

function mat2js(mat) {
  const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
  return matjs
}

export default function RobotArmComponent({ myview }) {
  const [module, setModule] = useState()

  const cam = useRef()
  const orbControlsRef = useRef()

  const { height: viewPortHeight, width: viewPortWidth } = useWindowDimensions()

  // useEffect(() => {
  //   cam.current.lookAt(1, 0, 0)
  // }, [])

  // useEffect(() => {
  //   createModule().then((Module) => {
  //     console.log('Hi from RobotArm module')
  //     Module['main']()
  //     var mat = Module['getMatrixFloat']()
  //     setModule(Module)
  //     const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
  //     console.log(matjs)
  //     return () => setModule(null)
  //   })
  // }, [])
  //   const orbTarget = useMemo(() =>
  // {
  //   const mat = mat2js(module['getTransformByID']("camera_link_4"));
  //   return [mat[14], mat[13], mat[12]]
  // }, [module])

  useEffect(() => {
    createVicarious().then((module) => {
      console.log('Hi from Vicarious module')
      module['main']()
      // var mat = Module['myFun2']()
      setModule(module)

      // console.log(orbControlsRef)
      const mat = mat2js(module['getTransformByID']('camera_link_4'))
      console.log(orbControlsRef)
      console.log(mat)
      // orbControlsRef.current.target.x = mat[12];
      // orbControlsRef.current.target.y = mat[13]-0.035;
      // orbControlsRef.current.target.z = mat[14];

      cam.current.position.x = mat[12] + 0.095
      cam.current.position.y = mat[13] + 0.01
      cam.current.position.z = mat[14] + 0.12
      // orbControlsRef

      // const cameraTran = mat2js(module['getTransformByID']("camera_link_4"));
      // console.log(cameraTran)

      // var mat = Module['myFun2']()
      // const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
      // console.log(matjs)
      return () => setModule(null)
    })
  }, [orbControlsRef])

  useEffect(() => {
    console.log(cam)
  }, [cam])

  /*
   * ================================
   * Commented by : Suvrat Ram Joshi
   * ================================
   * Try to do some formatting of the code to improve the readability.
   * Most IDEs and code-editors come with formatter by default. If not
   * they still support formatter as extensions. There is Prettier and other
   * react code formatters available as extension in VSCode if you use that
   * code-editor.
   * Also we can clean up the unwanted code and unwanted imports.
   */
  console.log(cam)
  return (
    <View track={myview}>
      {/*<Perf />*/}
      <color attach="background" args={['rgb(245, 245, 245)']} />
      <MyScene />
      <VicariousRobot module={module} linkID={1} position={[0, 0, 12]} />
      <OrbitControls ref={orbControlsRef} makeDefault target={[1.0338207560699435, 0.7783518367721509 - 0.04, 0.04839214343880045]} />
      {/*<OrbitControls ref={orbControlsRef} makeDefault />*/}

      {/* Configure the below component when working for desktop screen */}
      {isDesktopScreen(viewPortWidth) && (
        <PerspectiveCamera
          ref={cam}
          makeDefault
          fov={40}
          position={[1.0338207560699435 + 0.125, 0.7783518367721509 - 0.05 + 0.015, 0.04839214343880045 + 0.12]}
        />
      )}

      {/* Configure the below component when working for mobile screen */}
      {isMobileScreen(viewPortWidth) && (
        <PerspectiveCamera
          ref={cam}
          makeDefault
          fov={90}
          position={[1.0338207560699435 + 0.125, 0.7783518367721509 - 0.05 + 0.015, 0.04839214343880045 + 0.12]}
        />
      )}
      {/*<PerspectiveCamera ref={cam} makeDefault fov={40} />*/}
    </View>
  )
}

/*
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * Instead of having this as a functional component we can also only
 * define this as a JSX chunk to a variable since we aren't passing
 * any arguments to this as props for customization.
 */
function MyScene() {
  return (
    <>
      <ambientLight intensity={1} />
      {/*<pointLight position={[20, 30, 10]} intensity={1} />*/}
      <pointLight position={[-10, -10, -10]} color="white" />

      <pointLight position={[1.5, 0.7, 0.15]} color="white" />
      <Environment preset="warehouse" />
    </>
  )
}

// return <>
//
//   <Perf position="top-left"/>
//
//   <OrbitControls makeDefault/>
//
//   <directionalLight castShadow position={[1, 2, 3]} intensity={1.5} shadow-normalBias={0.04}/>
//   <ambientLight intensity={0.5}/>
//
//   {/*<mesh castShadow position-x={ - 2 }>*/}
//   {/*    <sphereGeometry />*/}
//   {/*    <meshStandardMaterial color="orange" />*/}
//   {/*</mesh>*/}
//
//   {/*<mesh castShadow position-x={ 2 } scale={ 1.5 }>*/}
//   {/*    <boxGeometry />*/}
//   {/*    <meshStandardMaterial color="mediumpurple" />*/}
//   {/*</mesh>*/}
//
//   <mesh receiveShadow position-y={-1} rotation-x={-Math.PI * 0.5} scale={10}>
//     <planeGeometry/>
//     <meshStandardMaterial color="greenyellow"/>
//   </mesh>
//
//   <Suspense
//     fallback={
//       <Placeholder position-y={0.5} scale={[2, 3, 2]}/>
//     }
//   >
//     <Hamburger scale={0.35}/>
//   </Suspense>
//   <Fox/>
// </>
