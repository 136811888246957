import { Environment, OrbitControls, PerspectiveCamera, View } from '@react-three/drei'
import importContinuum from './moduleContinuum.mjs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Perf } from 'r3f-perf'
import ContinuumRobot from './components/ContinuumRobot'

function mat2js(mat) {
  const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
  return matjs
}

export default function ContinuumRobotComponent({ myview }) {
  const [module, setModule] = useState()

  const cam = useRef()
  const orbControlsRef = useRef()

  // useEffect(() => {
  //   cam.current.lookAt(1, 0, 0)
  // }, [])

  // useEffect(() => {
  //   createModule().then((Module) => {
  //     console.log('Hi from RobotArm module')
  //     Module['main']()
  //     var mat = Module['getMatrixFloat']()
  //     setModule(Module)
  //     const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
  //     console.log(matjs)
  //     return () => setModule(null)
  //   })
  // }, [])
//   const orbTarget = useMemo(() =>
// {
//   const mat = mat2js(module['getTransformByID']("camera_link_4"));
//   return [mat[14], mat[13], mat[12]]
// }, [module])

  useEffect(() => {
    importContinuum().then((module) => {
      console.log('Hi from Continuum Robot Module')
      module['main']()
      setModule(module)
      return () => setModule(null)
    })
  }, [])

  useEffect(() => {
    console.log(cam)
  }, [cam])

  console.log(cam)
  return (
    <View track={myview}>
      {/*<Perf />*/}
      <color attach="background" args={["rgb(245, 245, 245)"]} />
      <MyScene />
      {/*<OrbitControls makeDefault target={[0.8,1,0]}/>*/}
      {/*<OrbitControls ref={orbControlsRef} makeDefault target={[ 1.0338207560699435, 0.7783518367721509 -0.040, 0.04839214343880045 ]}/>*/}
      {/*<OrbitControls ref={orbControlsRef} makeDefault />*/}

      {/*<OrbitControls  makeDefault />*/}
      <OrbitControls ref={orbControlsRef} makeDefault target={[ 12, 0, 0 ]}/>
      {/*<OrbitControls  />*/}
      <PerspectiveCamera ref={cam} makeDefault fov={40} position={[ 20, 10, 10]} />
      {/*<PerspectiveCamera ref={cam} makeDefault fov={40} />*/}
      <ContinuumRobot module={module}/>
    </View>
  )
}

/*
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * Instead of having this as a functional component we can also only
 * define this as a JSX chunk to a variable since we aren't passing
 * any arguments to this as props for customization.
 */
function MyScene() {
  return (
    <>
      <ambientLight intensity={1} />
      <pointLight position={[20, 10, 5]} intensity={1} />

      <pointLight position={[20, 10, -15]} intensity={1} />
      {/*<pointLight position={[20, 10, 5]} intensity={1} />*/}
      {/*<pointLight position={[20, 30, 0]} intensity={1} />*/}
      {/*<pointLight position={[-10, -10, -10]} color="white" />*/}
      <pointLight position={[-10, -10, -10]} color="white" />
      <Environment preset="dawn" />
    </>
  )
}

// return <>
//
//   <Perf position="top-left"/>
//
//   <OrbitControls makeDefault/>
//
//   <directionalLight castShadow position={[1, 2, 3]} intensity={1.5} shadow-normalBias={0.04}/>
//   <ambientLight intensity={0.5}/>
//
//   {/*<mesh castShadow position-x={ - 2 }>*/}
//   {/*    <sphereGeometry />*/}
//   {/*    <meshStandardMaterial color="orange" />*/}
//   {/*</mesh>*/}
//
//   {/*<mesh castShadow position-x={ 2 } scale={ 1.5 }>*/}
//   {/*    <boxGeometry />*/}
//   {/*    <meshStandardMaterial color="mediumpurple" />*/}
//   {/*</mesh>*/}
//
//   <mesh receiveShadow position-y={-1} rotation-x={-Math.PI * 0.5} scale={10}>
//     <planeGeometry/>
//     <meshStandardMaterial color="greenyellow"/>
//   </mesh>
//
//   <Suspense
//     fallback={
//       <Placeholder position-y={0.5} scale={[2, 3, 2]}/>
//     }
//   >
//     <Hamburger scale={0.35}/>
//   </Suspense>
//   <Fox/>
// </>
