// const WarehousePage = () => {
//   console.log('Hello from WarehousePage')
//   return null
//   // <>
//   //   <section className='section'>
//   //     <h2>products</h2>
//   //   </section>
//   // </>
// }

// export default WarehousePage

import { useState, useRef, useEffect, useCallback } from 'react'

import useResize from '../../hooks/useResize'
import Modal from '../../components/Modal'
import SpeedDial from '../../components/SpeedDial'
// import MyGraph from './components/MyGraph'

/*
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * We can also have these constants defined in a separate file
 * like constants.js so that it is accessible across the whole website
 * components. But if these are specific to only a single component
 * then better to initialize it in the component scope only.
 */

const noOfCharts = 3 // CONFIGURE: No of charts to show
const verticalMarginPx = 20 // CONFIGURE: Margin between the adjacent charts
const chartWidthPercent = 40 // CONFIGURE: Percent of total available container width

const WarehousePage = () => {
  const containerRef = useRef(null)
  const { width: containerWidth, height: containerHeight } = useResize(containerRef)
  const [chartWidth, setChartWidth] = useState(null)
  const [chartHeight, setChartHeight] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const modalRef = useRef()

  useEffect(() => {
    let _chartHeight = containerHeight / noOfCharts - verticalMarginPx
    let _chartWidth = Math.round((chartWidthPercent / 100) * containerWidth)

    setChartHeight(Math.max(_chartHeight, 100))
    setChartWidth(Math.min(_chartWidth, 350))
  }, [containerWidth, containerHeight])

  const openModal = useCallback(() => {
    modalRef.current.style.display = 'block'
  }, [modalRef])

  // We are using setTimeout here for testing the functionality of
  // this loading screen but later when we configure the component
  // to call the HTTP request for fetching some data we can then move this
  // setIsLoading to the thenable block of the Promise.
  setTimeout(() => {
    setIsLoading(false)
  }, 1000)

  return (
    <>
      <div
        ref={containerRef}
        className="chart-container"
        style={{
          width: '100%',
          height: '60vh', // CONFIGURE: The height property here to relative viewport height (0 - 100)
          marginLeft: '0%', // CONFIGURE: The margin-left property here to relative value (0 - 100)
          alignItems: 'flex-start' // CONFIGURE: flex-start/flex-end to left/right position the chart
        }}>
        {isLoading && <div className="loading-screen">Loading Models...</div>}
        {/*{!isLoading && chartWidth && chartHeight && (*/}
        {/*  <>*/}
        {/*    {[...Array(noOfCharts).keys()].map((i) => (*/}
        {/*      <MyGraph key={i} width={chartWidth} height={chartHeight} />*/}
        {/*    ))}*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
      <Modal _ref={modalRef}>
        <h1>Autonomous Warehouse</h1>
        <span>Work in progress.</span>
        <br />
      </Modal>

      <SpeedDial refs={[modalRef]} githubLink={'https://github.com/jakub-kaminski/robotsim-private'} />
      <>
        <section className={'section'}></section>
      </>
    </>
  )
}
export default WarehousePage
