import { useState, useRef, useEffect, useCallback } from 'react'

import useResize from '../../hooks/useResize'
import MyGraph from './components/MyGraph'
import Modal from '../../components/Modal'
import SpeedDial from '../../components/SpeedDial'
import { useRobotArmStore } from './store/useRobotArmStore'

import './index.css'
import '../../styles.css'

/*
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * We can also have these constants defined in a separate file
 * like constants.js so that it is accessible across the whole website
 * components. But if these are specific to only a single component
 * then better to initialize it in the component scope only.
 */

const noOfCharts = 3 // CONFIGURE: No of charts to show
const verticalMarginPx = 20 // CONFIGURE: Margin between the adjacent charts
const chartWidthPercent = 40 // CONFIGURE: Percent of total available container width

const RobotArmPage = () => {
  const modalRef = useRef(null)

  const containerRef = useRef(null)
  const setSliderValue = useRobotArmStore((state) => state.setSliderValue)
  const setJ1TranslationValue = useRobotArmStore((state) => state.setJ1TranslationValue)
  const { width: containerWidth, height: containerHeight } = useResize(containerRef)
  const [chartWidth, setChartWidth] = useState(null)
  const [chartHeight, setChartHeight] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let _chartHeight = containerHeight / noOfCharts - verticalMarginPx
    let _chartWidth = Math.round((chartWidthPercent / 100) * containerWidth)

    setChartHeight(Math.max(_chartHeight, 100))
    setChartWidth(Math.min(_chartWidth, 350))
  }, [containerWidth, containerHeight])

  const openModal = useCallback(() => {
    modalRef.current.style.display = 'block'
  }, [modalRef])

  // We are using setTimeout here for testing the functionality of
  // this loading screen but later when we configure the component
  // to call the HTTP request for fetching some data we can then move this
  // setIsLoading to the thenable block of the Promise.
  setTimeout(() => {
    setIsLoading(false)
  }, 1000)

  const updateZustandStore = useCallback((e) => {
    setSliderValue(e.target.value)
  }, [])

  const updateJ1AngleCallback = useCallback((e) => {
    setJ1TranslationValue(e.target.value)
  }, [])

  return (
    <>
      <div
        ref={containerRef}
        className="chart-container"
        style={{
          width: '100%',
          height: '60vh', // CONFIGURE: The height property here to relative viewport height (0 - 100)
          marginLeft: '0%', // CONFIGURE: The margin-left property here to relative value (0 - 100)
          alignItems: 'flex-start' // CONFIGURE: flex-start/flex-end to left/right position the chart
        }}>
        {isLoading && <div className="loading-screen">Loading Models...</div>}
        {/*{!isLoading && chartWidth && chartHeight && (*/}
        {/*  <>*/}
        {/*    {[...Array(noOfCharts).keys()].map((i) => (*/}
        {/*      <MyGraph key={i} width={chartWidth} height={chartHeight} />*/}
        {/*    ))}*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
      <div
        ref={containerRef}
        className="chart-container"
        style={{
          width: '100%',
          height: '60vh', // CONFIGURE: The height property here to relative viewport height (0 - 100)
          marginLeft: '0%', // CONFIGURE: The margin-left property here to relative value (0 - 100)
          alignItems: 'flex-end' // CONFIGURE: flex-start/flex-end to left/right position the chart
        }}>
        {isLoading && <div className="loading-screen">Loading Models...</div>}
        {!isLoading && <></>}
        {/*{!isLoading && chartWidth && chartHeight && (*/}
        {/*  <>*/}
        {/*    {[...Array(noOfCharts).keys()].map((i) => (*/}
        {/*      <MyGraph key={i} width={chartWidth} height={chartHeight} />*/}
        {/*    ))}*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
      <div className="robotarm__div--slider-container">
        <div>
          <label htmlFor="a-slider"></label>
          <input
            id="a-slider"
            type="range"
            min={-0.7}
            max={0.7}
            step={0.01}
            defaultValue={0}
            className="slider"
            onChange={updateZustandStore}
          />
        </div>
        <div>
          <label htmlFor="b-slider"></label>
          <input
            id="b-slider"
            type="range"
            min={0.105}
            max={0.12}
            step={0.0001}
            defaultValue={0.12}
            className="slider"
            onChange={updateJ1AngleCallback}
          />
        </div>
      </div>

      {/* Modal component that has a reference so that it can be controlled from parent component */}
      <Modal _ref={modalRef}>
        <h1>Surgical Robot</h1>
        <span>
          This project is a realtime analytical kinematic simulation of a minimally-invasive surgical robot. The design imitates a
          commercial Vicarious Surgical &#174; robotic system. The default simulation view shows two robot arms and a camera module that
          pass through a cannula. This cannula would be normally inserted through a narrow incision to patient's abdomen region. As compared
          to classic surgical robotics systems, this novel robot design allows for much better robot manipulability, user experience, and,
          in certain cases, improved accessibility to regions of human anatomy otherwise not reachable by laparoscopic tools.
        </span>
        <br />
        <h2>Engineering Solution</h2>
        This 27-degree-of-freedom (dof) robot is a collection of four serially-connected kinematic chains. It features two 9-dof arms, a
        4-dof camera module, and a 5-dof external manipulator module - one which remains static most of the time during operation (presented
        with a bright gray color).
        <br />
        The most interesting problem was to create a hierarchy of motion planners and inverse kinematic solvers, especially the inverse
        kinematics of both 9-dof arms. In fact, the solution to this engineering problem is tractable primarily when divided into
        sub-problems. The planners have distinct tasks and priority: to reorient the robot and camera to the region of interest, define
        robot torso-to-gripper distance, map the task-space motion to the joint-space with, and also reduce the problem space of the arm.
        <br />
        <br />
        <div className="image-container">
          <div>
            <img src="/assets/robotArm/model.png" />
            <span>Robot frames and several geometric features used for inverse kinematics solution.</span>
          </div>
          <div>
            <img src="/assets/robotArm/virtual-link.jpg" />
            <span>Virtual link and end-effector frame of a gripper.</span>
          </div>
        </div>
        <h3>Task Space motion</h3>
        Both robot arms continuously change end-effector orientation and are instructed to follow spiral trajectory described by a quintic
        polynomial.
        <h3>Robot orientation and extended arm state</h3>
        User defines the region of interest as an angle at which the center of robot viewport should be located. This behaviour imitates a
        situation when a surgeon wants to look around or chance the region where he or she operates. In the demo, the robot is reoriented to
        this new region of interest by changing the configuration of robot torso (state of J1 joint in each arm). All trajectory input is
        transformed to a new region of interest. Arm expansion is defined as the distance between the torso and arm gripper. The expansion
        is also adjusted in the demo.
        <h3>Arm Inverse Kinematics</h3>
        Each arm can be formally considered a 8-dof arm that has seven physical joints and one virtual joint (instead of two grasping jaws),
        and additional ninth independent parameter that specifies the jaws open angle. In my solution I use a cascade of a few planners
        presented in the Figure 1. As a result, the arm inverse kinematics solution uses preexisting linear joint J1 solution (defined by
        robot orientation planner). This reduces the problem space to 7-dof, which still has 1-dof redundancy that is controlled in the null
        space. The existing solution is to define additional kinematic constrains for one or several joints. In my computation I ensured
        that the elbow center point will be always coplanar with a predefined plane, which yields tractable analytical solution and allows
        for predictable operation of the major collision zones of the robot arm. The core part of the solution is an intersection between
        the upper-arm sphere, an elbow plane, and a torus defined by the lower arm and the wrist. (Full explanation to be provided as a
        separate article in the future.)
        <h2>Development Process and Tools</h2>
        <span>
          First, I designed robot solid bodies in SolidWorks (based on the publicly available images of the Vicarious system), exported the
          design to the URDF format, and verified its correctness with the MATLAB &#174; Robotics System Toolbox. Then, I used URDF Parser
          to access URDF data in C++, and transformed it a separate three structure capable of storing robot joint states. This structure
          became a foundation to develop RBMotion that is a lightweight C++ library for rigid body motion of serial and parallel kinematic
          chains. The library offers a set of tools to compute forward and inverse kinematics for selected range of a kinematic chain, or
          can be used as an interface implemented by derived robot classes. One of them is the VicariousRobot class which implements a
          custom inverse kinematics solution for robot arms, grippers, camera module, and external large robot arm. The live demo is C++
          code is transcompiled to WebAssembly using the Emscripten toolchain.
        </span>
      </Modal>
      <SpeedDial refs={[modalRef]} githubLink={'https://github.com/jakub-kaminski/vicarious-rb-motion'} />
    </>
    // <>
    //     <section className={'section'}>
    //     </section>
    // </>
  )
}
export default RobotArmPage
