import { isChrome, isSafari, isOpera, isEdge, isEdgeChromium, isLegacyEdge } from 'react-device-detect'

export const isSupportedBrowser = () => {
    return true
  // if (isChrome || isOpera || isSafari || isEdge || isEdgeChromium || isLegacyEdge) {
  //   return true
  // }
}

export const isDesktopScreen = (viewPortWidth) => viewPortWidth > 992
export const isLaptopScreen = (viewPortWidth) => viewPortWidth >= 768 && viewPortWidth < 992
export const isTabletScreen = (viewPortWidth) => viewPortWidth >= 576 && viewPortWidth < 768
export const isMobileScreen = (viewPortWidth) => viewPortWidth < 576
