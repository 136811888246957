import React, { useRef, useEffect, useState } from 'react'
import { Clone, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

function mat2js(mat) {
  const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
  return matjs
}

export default function LowPolyShelf({shelfID, module}){
  const ref = useRef()
  const model = useGLTF("./models/warehouse/shelf-lowpoly-draco.glb")

  useFrame(function(state, delta) {
    ref.current.matrix.elements = mat2js(module['getShelfTransform'](shelfID))

  }, -2)

  return (
    <>
      <Clone
        ref={ref}
        object={model.scene}
        matrixAutoUpdate={false}
      />
    </>
  )
}

useGLTF.preload("./models/warehouse/shelf-lowpoly-draco.glb")