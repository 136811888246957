import { Navigate } from 'react-router-dom'

import { isSupportedBrowser } from '../utils/common'
import BgVideo from '../components/BgVideo'
import Footer from '../components/Footer'

const BrowserProblem = () => {
  return (
    <>
      {isSupportedBrowser() ? (
        <Navigate replace to="/demo-video" />
      ) : (
        <>
          <BgVideo />
          <div id="unsupported-browser">
            <div>Unsupported browser detected.</div>
            <div>
              Please use: <b>Chrome, Safari, or Edge</b>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  )
}
export default BrowserProblem
