import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

/* 
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * When using arrow function if the block statement is only 1-liner than
 * we can simply omit the parenthesis ({}) and the return keyword. It makes code
 * more cleaner and readble. But this could be subjective.
 * E.g.
 * setPage: (pagestring) => {
 *   set((state) => ({ currentPage: pagestring }))
 * }
 */
export default create(
  subscribeWithSelector((set) => {
    return {
      // blocksCount: 100,
      // Pages
      currentPage: 'robotArm',
      modelCredit: 'Designer Link',
      additonalInfo: '',

      setPage: (pagestring) => {
        // console.log("Setting page")
        set((state) => {
          return { currentPage: pagestring }
        })
      },

      setAdditionalInfo: (info) => {
        set((state) => {
          return { additionalInfo: info }
        })
      },

      setModelCredit: (credit) => {
        set((state) => {
          return { modelCredit: credit }
        })
      }
    }
  })
)
