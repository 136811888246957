// import "./styles.css";
import { Object3D } from 'three'
import { useRef } from 'react'
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { useContinuumRobotStore } from '../store/useContinuumRobotStore'
import { Clone, Line, TransformControls, useGLTF } from '@react-three/drei'
import MyFrame from './MyFrame'
import LaserLine from './LaserLine'

const dummy = new Object3D()
dummy.matrixAutoUpdate = false
extend({ OrbitControls })

function mat2js(mat) {
  const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
  return matjs
}

export default function ContinuumRobot({ module }) {
  const meshRef1 = useRef()
  const meshRef2 = useRef()
  const meshRef3 = useRef()
  const targetRef = useRef()
  const effectorRef = useRef()
  const refLaser = useRef()

  const filepath = './models/continuum/end-effector-draco.glb'
  useGLTF.preload(filepath)
  const model = useGLTF(filepath)

  useFrame(({ clock }) => {
    // console.log("before animation step")

    const x = useContinuumRobotStore.getState().x
    const y = useContinuumRobotStore.getState().y
    const z = useContinuumRobotStore.getState().z
    const target = useContinuumRobotStore.getState().target
    module['setXYZ'](x, y, z)
    module['setTargetX'](target)

    module['animationStep']()

    const targetReceived = module['getTargetX']()
    // console.log("after animation step")
    const tubeSegments = 50

    refLaser.current.matrix.elements = mat2js(module['getLaserTransform']())

    targetRef.current.position.x = targetReceived

    effectorRef.current.matrix.elements = mat2js(module['getTubeSegmentTransform'](2, 49))

    let inst = 0
    let i = 0
    for (let j = 0; j < tubeSegments; j++) {
      dummy.matrix.elements = mat2js(module['getTubeSegmentTransform'](i, j))
      // dummy.updateMatrix()
      meshRef1.current.setMatrixAt(j, dummy.matrix)
      meshRef1.current.instanceMatrix.needsUpdate = true
    }

    i = 1
    for (let j = 0; j < tubeSegments; j++) {
      dummy.matrix.elements = mat2js(module['getTubeSegmentTransform'](i, j))
      // dummy.updateMatrix()
      meshRef2.current.setMatrixAt(j, dummy.matrix)
      meshRef2.current.instanceMatrix.needsUpdate = true
    }
    i = 2
    for (let j = 0; j < tubeSegments; j++) {
      dummy.matrix.elements = mat2js(module['getTubeSegmentTransform'](i, j))
      // dummy.updateMatrix()
      meshRef3.current.setMatrixAt(j, dummy.matrix)
      meshRef3.current.instanceMatrix.needsUpdate = true
    }
  })

  const radius = 0.10
  const val1 = 8
  return (
    <>
      {/*<TransformControls></TransformControls>*/}
      <instancedMesh ref={meshRef1} args={[null, null, 50]}>
        <torusGeometry args={[0.8, radius, val1, 32]}></torusGeometry>
        <meshPhongMaterial color={[0.1, 0.1, 0.1]} />
      </instancedMesh>
      <instancedMesh ref={meshRef2} args={[null, null, 50]}>
        <torusGeometry args={[0.75, radius, val1, 32]}></torusGeometry>
        <meshPhongMaterial color={[0.2, 0.2, 0.2]} />
      </instancedMesh>
      <instancedMesh ref={meshRef3} args={[null, null, 50]}>
        <torusGeometry args={[0.70, radius, val1, 32]}></torusGeometry>
        <meshPhongMaterial color={[0.3, 0.3, 0.3]} />
      </instancedMesh>

      <mesh ref={targetRef}>
        <sphereBufferGeometry args={[0.2, 24, 24]} />
        <meshStandardMaterial color={'red'} />
      </mesh>

      <>
        <Clone
          ref={effectorRef}
          object={model.scene}
          // scale={0.35}
          matrixAutoUpdate={false}
          // position={position}
        />
        {/*<MyFrame module={module} tubeid={0} />*/}

        <Line
          ref={refLaser}
          points={[[0, 0, 0], [0, 0, 30]]}       // Array of points, Array<Vector3 | Vector2 | [number, number, number] | [number, number] | number>
          color='red'                   // Default
          lineWidth={2}                   // In pixels (default)
          dashed={false}                  // Default
          matrixAutoUpdate={false}
        />
        {/*<LaserLine module={module}/>*/}
      </>
      {/*<CameraControls />*/}
    </>
  )
};

// const CameraControls = () => {
//   const {
//     camera,
//     gl: { domElement }
//   } = useThree()
//   // Ref to the controls, so that we can update them on every frame using useFrame
//   const controls = useRef()
//   useFrame((state) => controls.current.update())
//   return <orbitControls ref={controls} args={[camera, domElement]} />
// }
//
// export default function App() {
//   return (
//     <Canvas camera={{ position: [0, 20, 5] }}>
//       <ambientLight intensity={1} />
//       <directionalLight />
//       <Cubes />
//       <CameraControls />
//     </Canvas>
//   );
// }
