import { useState, useRef, useEffect, useCallback } from 'react'

import useResize from '../../hooks/useResize'
import { useContinuumRobotStore } from './store/useContinuumRobotStore'
import Modal from '../../components/Modal'
import SpeedDial from '../../components/SpeedDial'

import '../../styles.css'
import './index.css'

/*
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * We can also have these constants defined in a separate file
 * like constants.js so that it is accessible across the whole website
 * components. But if these are specific to only a single component
 * then better to initialize it in the component scope only.
 */

const noOfCharts = 3 // CONFIGURE: No of charts to show
const verticalMarginPx = 20 // CONFIGURE: Margin between the adjacent charts
const chartWidthPercent = 40 // CONFIGURE: Percent of total available container width

const ContinuumRobotPage = () => {
  const containerRef = useRef(null)
  const setX = useContinuumRobotStore((state) => state.setX)
  const setY = useContinuumRobotStore((state) => state.setY)
  const setZ = useContinuumRobotStore((state) => state.setZ)
  const setTarget = useContinuumRobotStore((state) => state.setTarget)
  const { width: containerWidth, height: containerHeight } = useResize(containerRef)
  const [chartWidth, setChartWidth] = useState(null)
  const [chartHeight, setChartHeight] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const modalRef = useRef()

  useEffect(() => {
    let _chartHeight = containerHeight / noOfCharts - verticalMarginPx
    let _chartWidth = Math.round((chartWidthPercent / 100) * containerWidth)

    setChartHeight(Math.max(_chartHeight, 100))
    setChartWidth(Math.min(_chartWidth, 350))
  }, [containerWidth, containerHeight])

  const openModal = useCallback(() => {
    modalRef.current.style.display = 'block'
  }, [modalRef])

  // We are using setTimeout here for testing the functionality of
  // this loading screen but later when we configure the component
  // to call the HTTP request for fetching some data we can then move this
  // setIsLoading to the thenable block of the Promise.
  setTimeout(() => {
    setIsLoading(false)
  }, 1000)

  const updateXCallback = useCallback((e) => {
    setX(e.target.value)
  }, [])

  const updateYCallback = useCallback((e) => {
    setY(e.target.value)
  }, [])

  const updateZCallback = useCallback((e) => {
    setZ(e.target.value)
  }, [])

  const updateTargetCallback = useCallback((e) => {
    setTarget(e.target.value)
  }, [])

  return (
    <>
      <div
        ref={containerRef}
        className="chart-container"
        style={{
          width: '100%',
          height: '90vh', // CONFIGURE: The height property here to relative viewport height (0 - 100)
          marginLeft: '0%', // CONFIGURE: The margin-left property here to relative value (0 - 100)
          alignItems: 'flex-start' // CONFIGURE: flex-start/flex-end to left/right position the chart
        }}>
        {isLoading && <div className="loading-screen">Loading Models...</div>}
        {!isLoading && <></>}
      </div>
      {/* All the sliders can be kept in a single container */}
      {/* The container can have a flex-layout with column arrangement to vertically stack the sliders */}
      <div className="continuum__div--slider-container">
        <div>
          <label htmlFor="x-slider">X</label>
          <input
            id="x-slider"
            type="range"
            min={5.0}
            max={10.0}
            step={0.001}
            defaultValue={7.5}
            style={{ bottom: 180 }}
            className="slider"
            onChange={updateXCallback}
          />
        </div>
        <div>
          <label htmlFor="y-slider">Y</label>
          <input
            id="y-slider"
            type="range"
            min={-3.0}
            max={3.0}
            step={0.001}
            defaultValue={0.0}
            className="slider"
            onChange={updateYCallback}
          />
        </div>
        <div>
          <label htmlFor="z-slider">Z</label>
          <input
            id="z-slider"
            type="range"
            min={-2.5}
            max={2.5}
            step={0.001}
            defaultValue={0.0}
            className="slider"
            onChange={updateZCallback}
          />
        </div>
        <div>
          <label htmlFor="t-slider">TARGET</label>
          <input
            id="t-slider"
            type="range"
            min={13}
            max={21}
            step={0.001}
            defaultValue={15}
            className="slider"
            onChange={updateTargetCallback}
          />
        </div>
      </div>
      {/* Modal component that has a reference so that it can be controlled from parent component */}
      <Modal _ref={modalRef}>
        <h1>Surgical Robot</h1>
        <span>
          This project is a realtime numerical kinematic simulation of a soft continuum robot.
          Description in progress.
        </span>
      </Modal>
      <SpeedDial refs={[modalRef]} githubLink={'https://github.com/jakub-kaminski/continuum-robot'} />
    </>
    // <>
    //     <section className={'section'}>
    //     </section>
    // </>
  )
}
export default ContinuumRobotPage
