import create from "zustand"
import {subscribeWithSelector} from 'zustand/middleware'

/* 
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * When using arrow function if the block statement is only 1-liner than
 * we can simply omit the parenthesis ({}) and the return keyword. It makes code
 * more cleaner and readble. But this could be subjective.
 * E.g.
 * setTorque: (torqueVal) => {
 *   set((state) => ({ currentTorque: torqueVal }))
 * }
 * 
 * Also, since we only have one hook here so we can do a default export
 * instead of named export.
 * 
 * Also, this file has too many unnecessary and commented code. So, better to
 * do some cleanup.
 */
// export default create(subscribeWithSelector((set) =>


export const useRobotArmStore = create(
  subscribeWithSelector((set) => {
    return {
      // blocksCount: 100,
      // Pages
      currentTorque: 0,
      currentSliderValue: 0,
      currentJ1TranslationValue: 0.120,

      setTorque: (torqueVal) => {
        // console.log("Setting page")
        set((state) => {
          return { currentTorque: torqueVal }
        })
      },

      setSliderValue: (val) => {
        set((state) => {
          return { currentSliderValue: val }
        })
      },

      setJ1TranslationValue: (val) => {
        set((state) => {
          return { currentJ1TranslationValue: val }
        })
      }
    }
  })
)

// export const useRobotArmStore = create(subscribeWithSelector((set) =>
// {
//   return {
//     // blocksCount: 100,
//     // Pages
//     currentTorque: 0,
//
//     setTorque: (torqueVal) =>
//     {
//       // console.log("Setting page")
//       set((state) =>
//       {
//         return {currentTorque: torqueVal}
//       })
//     },
//   }
// }))
//
//
