import {
  View,
  Preload,
  OrbitControls,
  PerspectiveCamera,
  CameraShake,
  TransformControls,
  Environment,
  Text
} from '@react-three/drei'
import useRefs from 'react-use-refs'
import importWarehouse from './moduleWarehouse.mjs'
import { Suspense, useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { WarehouseWorld } from './components/WarehouseWorld'
import { WarehouseWorldB } from './components/WarehouseWorldB'
import {Perf} from 'r3f-perf'
import WarehouseWorldC from './components/WarehouseWorldC'

/*
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * We can keep this also inside the components folder of the RobotArm module.
 * Also the parent folder is named simulations, but I think we can have these
 * modules kept under the pages folder itself, since ultimately these are the
 * app pages. But it's a trivial thing so we can leave it as it is too. Matter of
 * preference.
 */
export default function WarehouseComponent({ myview }) {
  const [module, setModule] = useState()

  const cam = useRef()

  useEffect(() => {
    importWarehouse().then((module) => {
      console.log('Hi from Warehouse module')
      module['main']();
      setModule(module)
      return () => setModule(null)
    })
  }, [])

  // useEffect(() => {
  //   createVicarious().then((Module) => {
  //     console.log('Hi from Vicarious module')
  //     Module['main']()
  //     var mat = Module['myFun2']()
  //     setModule(Module)
  //
  //     // var mat = Module['myFun2']()
  //     const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
  //     console.log(matjs)
  //     return () => setModule(null)
  //   })
  // }, [])

  /*
   * ================================
   * Commented by : Suvrat Ram Joshi
   * ================================
   * Try to do some formatting of the code to improve the readability.
   * Most IDEs and code-editors come with formatter by default. If not
   * they still support formatter as extensions. There is Prettier and other
   * react code formatters available as extension in VSCode if you use that
   * code-editor.
   * Also we can clean up the unwanted code and unwanted imports.
   */
  return (
    <View track={myview}>
      <Suspense fallback={null}>
      {/*<Perf />*/}
      {/*<color attach="background" args={['ivory']} />*/}
      <color attach="background" args={["rgb(245, 245, 245)"]} />
      <MyScene />
      {/*<Apple scale={14} />*/}
      {/*<Hamburger module={module} linkID={0} />*/}
      {/*<Hamburger module={module} linkID={1} position={[0, 0, 12]}/>*/}
      {/*<Hamburger module={module} linkID={0} position={[0, 0, 0]}/>*/}
      {/*<HamburgerMulti module={module} linkID={1} position={[0, 0, 12]} />*/}
      {/*<VicariousRobot module={module} linkID={1} position={[0, 0, 12]} />*/}
      {/*<OrbitControls makeDefault target={[0.9*8,-1,-0.9*8]}/>*/}
      <OrbitControls makeDefault target={[0.0,1.0,0.0]}/>
      <WarehouseWorld module={module} />
      {/*<WarehouseWorldB module={module} />*/}
      {/*<WarehouseWorldC module={module} />*/}
      {/*<OrbitControls  />*/}
      {/*<PerspectiveCamera ref={cam} makeDefault fov={40} position={[8*0.9, 9, 8]} />*/}
      <PerspectiveCamera ref={cam} makeDefault fov={40} position={[-2, 2, 3]} />
        <Text
          color={"salmon"}
          position-y={ 1.0 }
          position-x={ 1.2}
          scale={4}
        >
          WORK IN PROGRESS. </Text>
      </Suspense>
    </View>
  )
}

/*
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * Instead of having this as a functional component we can also only
 * define this as a JSX chunk to a variable since we aren't passing
 * any arguments to this as props for customization.
 */
function MyScene() {
  return (
    <>
      <ambientLight intensity={1} />
      <pointLight position={[20, 30, 10]} intensity={1} />
      <pointLight position={[-10, -10, -10]} color="blue" />
      <Environment preset="dawn" />

      {/*<Environment preset="warehouse" />*/}
    </>
  )
}

// return <>
//
//   <Perf position="top-left"/>
//
//   <OrbitControls makeDefault/>
//
//   <directionalLight castShadow position={[1, 2, 3]} intensity={1.5} shadow-normalBias={0.04}/>
//   <ambientLight intensity={0.5}/>
//
//   {/*<mesh castShadow position-x={ - 2 }>*/}
//   {/*    <sphereGeometry />*/}
//   {/*    <meshStandardMaterial color="orange" />*/}
//   {/*</mesh>*/}
//
//   {/*<mesh castShadow position-x={ 2 } scale={ 1.5 }>*/}
//   {/*    <boxGeometry />*/}
//   {/*    <meshStandardMaterial color="mediumpurple" />*/}
//   {/*</mesh>*/}
//
//   <mesh receiveShadow position-y={-1} rotation-x={-Math.PI * 0.5} scale={10}>
//     <planeGeometry/>
//     <meshStandardMaterial color="greenyellow"/>
//   </mesh>
//
//   <Suspense
//     fallback={
//       <Placeholder position-y={0.5} scale={[2, 3, 2]}/>
//     }
//   >
//     <Hamburger scale={0.35}/>
//   </Suspense>
//   <Fox/>
// </>




// ******************


// import { View, Preload, OrbitControls, PerspectiveCamera, CameraShake, TransformControls, Environment } from '@react-three/drei'
// import { Flash } from '../../Models'
// import useRefs from 'react-use-refs'
// import { useEffect, useState } from 'react'
// import createModule from './warehouse'
//
// /*
//  * ================================
//  * Commented by : Suvrat Ram Joshi
//  * ================================
//  * We can keep this also inside the components folder of the Warehouse module.
//  * Also the parent folder is named simulations, but I think we can have these
//  * modules kept under the pages folder itself, since ultimately these are the
//  * app pages. But it's a trivial thing so we can leave it as it is too. Matter of
//  * preference.
//  */
// export default function WarehouseComponent({myview})
// {
//   const [module, setModule] = useState()
//
//   useEffect(
//     () => {
//       createModule().then((Module) => {
//         console.log('Hi from Warehouse module')
//         var mat = Module['returnVectorData']()
//         setModule(Module)
//         const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
//         console.log(matjs)
//         return () => setModule(null)
//       })
//     },
//     [])
//
//   // var myVec = Module['addVectorElement']();
//   // var startTime = performance.now()
//
//   // for (var i = 0; i < 1000; i++) {
//   // console.log("Vector Value: ", mySubVector.get(i));
//   // var mySubVector = module['getSubvector'](1);
//   // }
//
//   // var endTime = performance.now()
//   // console.log(`Call to doSomething took ${endTime - startTime} milliseconds`)
//
//   // console.log("MyVec size: ", myVec.size())
//
//   // var retVector = Module['returnVectorData']();
//   // console.log("retVec size: ", retVector.size())
//
//   // for (var i = 0; i < mySubVector.size(); i++) {
//   //   console.log("Vector Value: ", mySubVector.get(i));
//   // }
//
//   /*
//    * ================================
//    * Commented by : Suvrat Ram Joshi
//    * ================================
//    * Try to do some formatting of the code to improve the readability.
//    * Most IDEs and code-editors come with formatter by default. If not
//    * they still support formatter as extensions. There is Prettier and other
//    * react code formatters available as extension in VSCode if you use that
//    * code-editor.
//    * Also we can clean up the unwanted code and unwanted imports.
//    */
//   return <View track={myview}>
//     <color attach="background" args={['azure']} />
//     <MyScene2 />
//     <Flash scale={3} />
//     <OrbitControls />
//     <PerspectiveCamera makeDefault fov={40} position={[0, 0, 6]} />
//   </View>
// }
//
// /*
//  * ================================
//  * Commented by : Suvrat Ram Joshi
//  * ================================
//  * Instead of having this as a functional component we can also only
//  * define this as a JSX chunk to a variable since we aren't passing
//  * any arguments to this as props for customization.
//  */
// function MyScene2() {
//   return (
//     <>
//       <ambientLight intensity={1} />
//       <pointLight position={[20, 30, 10]} intensity={1} />
//       <pointLight position={[-10, -10, -10]} color="blue" />
//       <Environment preset="dawn" />
//     </>
//   )
// }
