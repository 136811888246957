import { useEffect, useRef } from 'react'

const Modal = ({ _ref }) => {

  return (
    <div ref={_ref} className='robotarm__div--modal'>
      <div className='content'>
        <div className='header'>
        </div>
        <div className='body'>
          <h1>RobotSim.org</h1>
          <span>
            This project is a set of robot simulations for demonstration and educational purposes, built with JavaScript, C++, WebAssembly (Emscripten Toolchain),
            React, ThreeJS (ReactThreeFiber).
          </span>
          <br />
          <br />
          <span>
            Author: Jakub Kaminski (C++ and robotics algorithms, 3D simulations, descriptions, React&JavaScript website content, UI design)
          </span>
          <br />
          <br />
          <span>
            Supporters and contributors:
          <br />
          <br />
            Suvrat Joshi (JavaScript refactoring and CSS styling)
          </span>
        </div>
      </div>
    </div>
  )
}


const AboutPage = () => {
  const modalRef = useRef(null)

  /*
   * ================================
   * Commented by : Suvrat Ram Joshi
   * ================================
   * These CSS classes are defined in the styles.css. As I mentioned in the comment kept on
   * styles.css we can have these CSS rules kept in a CSS module specific to this page only
   * or also have these rules maintained in the App.css or a common CSS file for all the pages
   * kept under pages folder.
   */

  useEffect(() => {
    modalRef.current.style.display = 'block'
  }, [])

  // const openModal = useCallback(() => {
  // }, [])

  return (
    <Modal _ref={modalRef} display={'block'} />

    // <section className="section">
    //   <div className="about">
    //     <h1>Sample Title</h1>
    //     <span>
    //       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
    //       enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit
    //       amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
    //     </span>
    //     <br />
    //     <h1>Sample Title</h1>
    //     <span>
    //       Ecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit
    //       in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
    //       officia deserunt mollit anim id est laborum.
    //     </span>
    //   </div>
    // </section>
  )
}
export default AboutPage
