import { BrowserRouter, Routes, Route } from 'react-router-dom'

import SharedLayout from './layouts/SharedLayout'

// "*Component" - R3F stuff for given simulation
// "*Page" - non R3F stuff for given simulation
import RobotArmPage from './simulations/RobotArm/RobotArmPage'
import WarehousePage from './simulations/Warehouse/WarehousePage'

// "Normal" pages that have no associated R3F components
// When they are active, no View is active
import AboutPage from './pages/AboutPage'
import ErrorPage from './pages/ErrorPage'
import BrowserProblem from './pages/BrowserProblem'
import DemoVideo from './pages/DemoVideo'
import ReactGA from 'react-ga4'
import { useEffect } from 'react'
import ContinuumRobotPage from './simulations/ContinuumRobot/ContinuumRobotPage'


export function App() {
  useEffect(() => {
    ReactGA.initialize("G-0HTDX7L5KV")
    ReactGA.send("pageview");
  }, [])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/browser-problem" element={<BrowserProblem />} />
        <Route path="/" element={<SharedLayout />}>
          <Route index element={<RobotArmPage />} /> {} {/*Default Page*/}
          <Route path="warehouse" element={<WarehousePage />} />
          <Route path="continuum" element={<ContinuumRobotPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="demo-video" element={<DemoVideo />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
