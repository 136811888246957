import { useCallback } from 'react'

import './index.css'

export default function Modal({ _ref, children }) {
  const closeModal = useCallback(() => {
    _ref.current.style.display = 'none'
  }, [_ref])

  return (
    <div ref={_ref} className="infomodal__div--modal">
      <div className="content">
        <div className="header">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
        </div>
        <div className="body">{children}</div>
      </div>
    </div>
  )
}
