import React, { useRef, useEffect, useState } from 'react'
import Floor from './Floor'
import Kiva from './Kiva'
import LowPolyKiva from './LowPolyKiva'
import LowPolyShelf from './LowPolyShelf'
import { Line, Torus } from '@react-three/drei'
import MyLine from './MyLine'
import MyTorus from './MyTorus'
// import { Clone, useGLTF } from '@react-three/drei'
// import { useFrame } from '@react-three/fiber'

// import {useRobotArmStore} from '../store/useRobotArmStore'
// import RobotLink from './RobotLink'

// function mat2js(mat) {
//   const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
//   return matjs
// }

// function Box(props: ThreeElements['mesh']) {
//   const mesh = useRef<THREE.Mesh>(null!)
//   const [hovered, setHover] = useState(false)
//   const [active, setActive] = useState(false)
//   useFrame((state, delta) => (mesh.current.rotation.x += delta))
//   return (
//     <mesh
//       {...props}
//       ref={mesh}
//       scale={active ? 1.5 : 1}
//       onClick={(event) => setActive(!active)}
//       onPointerOver={(event) => setHover(true)}
//       onPointerOut={(event) => setHover(false)}>
//       <boxGeometry args={[1, 1, 1]} />
//       <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
//     </mesh>
//   )
// }

export function WarehouseWorld({ module }) {
  // const setTorque = useRobotArmStore((state) => state.setTorque)
  const xSize = 38
  const ySize = 38
  const tileSize = 0.9

  return (
    <>
      {/*<Kiva robotID={0} module={module} />*/}
      {/*/!*<Floor/>*!/*/}

      {/*<Box position={[1.2, 0, 0]} />*/}


      {/*<mesh>*/}
      {/*  <boxGeometry args={[0.4, 0.8, 0.4]} position={[0,0,0]} />*/}
      {/*  <meshStandardMaterial color={"orange"}/>*/}
      {/*</mesh>*/}

      {/*<boxGeometry />*/}
      <mesh scale-x={xSize * tileSize} scale-y={ySize * tileSize}
            position={[xSize * 0.5 * tileSize, 0, -ySize * 0.5 * tileSize]} rotation-x={-Math.PI * 0.5}>
        <planeGeometry />
        <meshBasicMaterial args={[{ color: 'gray' }]} />
        {/*<meshStandardMaterial color="greenyellow"/>*/}
      </mesh>

      {[...Array(52)].map((x, i) =>
        // <Kiva robotID={i} module={module} />
        <LowPolyKiva robotID={i} module={module} />
      )}
      {[...Array(360)].map((x, i) =>
        // <Kiva robotID={i} module={module} />
        <LowPolyShelf shelfID={i} module={module} />
      )}
    </>

  )
}
