import { useFrame } from '@react-three/fiber'
import { useEffect, useRef } from 'react'
import { Line } from '@react-three/drei'
import { Object3D } from 'three'

const dummy = new Object3D();

function mat2js(mat) {
  const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
  return matjs
}

export default function MyTorus({ id, color, module, priority }) {
  const meshRef = useRef()

  useEffect(() => {
    // console.log(model)
    // model.materials[''].color.r = color[0]
    // model.materials[''].color.g = color[1]
    // model.materials[''].color.b = color[2]
    // console.log()
    // model.materials.color = {isColor: true, r: 0.5, g: 0.5, b:0.5}

  }, [])

  // useFrame(function(state, delta) {
  //   console.log(ref.current)
  //   // ref.current.matrix.elements = mat2js(module['getTransformByID'](link_name))
  //   ref.current.matrix.elements = [1,0,0,0,0,1,0,0,0,0,1,0,0.5,0.5,0.5,1]
  //
  //   if (id === 0) {
  //   }
  //   // console.log(link_name)
  //   // console.log(counter)
  //
  // }, priority)

    useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    // meshRef.current.rotation.x = Math.sin(time / 4);
    // meshRef.current.rotation.y = Math.sin(time / 2);
    let i = 0;
    const amount = 10;
    const offset = (amount - 1) / 2;

    for (let x = 0; x < amount; x++) {
      for (let y = 0; y < amount; y++) {
        for (let z = 0; z < amount; z++) {
          // dummy.position.set(offset - x, offset - y, offset - z);
          // dummy.rotation.y =
          //   Math.sin(x / 2 + time) +
          //   Math.sin(y / 3 + time) +
          //   Math.sin(z / 4 + time);
          // dummy.rotation.z = dummy.rotation.y * 2;

          // dummy.updateMatrix();

          // meshRef.current.setMatrixAt(i++, dummy.matrix);
        }
        meshRef.current.instanceMatrix.needsUpdate = true;
      }
    }
  });


  return (
    <instancedMesh ref={meshRef} args={[null, null, 1]}>
      <torusGeometry
        matrixAutoUpdate={false}
        args={[0.05, 0.005, 32, 32]}></torusGeometry>
      <meshPhongMaterial color="tomato" />
      {/*<meshBasicMaterial color='hotpink' />*/}
    </instancedMesh>
  )
}


