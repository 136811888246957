// import "./styles.css";
import { Object3D } from 'three'
import { useRef } from 'react'
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { Clone, Line, TransformControls, useGLTF } from '@react-three/drei'

const dummy = new Object3D()
dummy.matrixAutoUpdate = false
extend({ OrbitControls })

function mat2js(mat) {
  const matjs = new Array(mat.size()).fill(0).map((_, id) => mat.get(id))
  return matjs
}

export default function WarehouseWorldC({ module }) {
  const kivaRef = useRef()

  const filepath = './models/warehouse/robot-body-lowpoly-draco.glb'
  useGLTF.preload(filepath)
  const { nodes, materials } = useGLTF('./models/warehouse/robot-body-lowpoly-draco.glb');
  // const geo = nodes.mesh_id4003.geometry.clone()
  const geo = nodes.mesh_id4003.geometry.clone()
  console.log(materials)

  useFrame(({ clock }) => {
    // console.log("before animation step")
    const numRobots = 52

    for (let j = 0; j < numRobots; j++) {
      dummy.matrix.elements = mat2js(module['getRobotTransform'](j, 0))
      // console.log(dummy.matrix)
      // dummy.updateMatrix()
      kivaRef.current.setMatrixAt(j, dummy.matrix)
      kivaRef.current.instanceMatrix.needsUpdate = true
    }
  })

  return (
    <>
      <instancedMesh material={materials} ref={kivaRef} args={[geo, null, 1]}>
        {/*<meshNormalMaterial attach="material" />*/}
      </instancedMesh>
    </>
  )
};

// const CameraControls = () => {
//   const {
//     camera,
//     gl: { domElement }
//   } = useThree()
//   // Ref to the controls, so that we can update them on every frame using useFrame
//   const controls = useRef()
//   useFrame((state) => controls.current.update())
//   return <orbitControls ref={controls} args={[camera, domElement]} />
// }
//
// export default function App() {
//   return (
//     <Canvas camera={{ position: [0, 20, 5] }}>
//       <ambientLight intensity={1} />
//       <directionalLight />
//       <Cubes />
//       <CameraControls />
//     </Canvas>
//   );
// }
