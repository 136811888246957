import { useCallback, useEffect } from 'react'
import { useLocation, NavLink } from 'react-router-dom'

import useStorage from '../store/useStore'

const navItems = [
  {
    path: '/',
    title: 'Surgical Robot'
  },
  {
    path: '/warehouse',
    title: 'Autonomous Warehouse'
  },
  {
    path: '/continuum',
    title: 'Continuum Robot'
  },
  {
    path: '/about',
    title: 'About'
  }
]

const StyledNavbar = () => {
  const location = useLocation()
  const setPage = useStorage((state) => state.setPage)
  const setAdditionalInfo = useStorage((state) => state.setAdditionalInfo)

  const hamburgerIconClickHandler = useCallback(() => {
    const navbarLinks = document.getElementsByClassName('navbar-links')[0]
    navbarLinks.classList.toggle('active')
  }, [])

  useEffect(() => {
    let activeURLPath = location.pathname.split('/')[1]
    if (activeURLPath === '') {
      activeURLPath = 'robotArm'
    }
    setPage(activeURLPath)
    if(activeURLPath === 'robotArm'){
      setAdditionalInfo("Inspired by the Vicarious Surgical robot")
    }
    if(activeURLPath === 'warehouse'){
      setAdditionalInfo("Inspired by Amazon Robotics & Kiva Systems")
    }
    if(activeURLPath === 'continuum'){
      setAdditionalInfo("")
    }
    if(activeURLPath === 'about'){
      setAdditionalInfo("")
    }

  }, [location, setPage])

  return (
    <nav className={'navbar'}>
      <div className="brand">
        <img src="android-chrome-192x192.png" width={30} height={30} />
        <a className="link" href="https://robotsim.org/">
          robotsim.org
        </a>
      </div>
      <hr />
      <a href="#" className="toggle-button" onClick={hamburgerIconClickHandler}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </a>
      <div className="navbar-links">
        {navItems.map((item, index) => (
          <NavLink key={index} to={item.path} className={({ isActive }) => (isActive ? 'link active' : 'link')}>
            {item.title}
          </NavLink>
        ))}
      </div>
    </nav>
  )
}
export default StyledNavbar
