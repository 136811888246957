import create from "zustand"
import {subscribeWithSelector} from 'zustand/middleware'

/* 
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * When using arrow function if the block statement is only 1-liner than
 * we can simply omit the parenthesis ({}) and the return keyword. It makes code
 * more cleaner and readble. But this could be subjective.
 * E.g.
 * setTorque: (torqueVal) => {
 *   set((state) => ({ currentTorque: torqueVal }))
 * }
 * 
 * Also, since we only have one hook here so we can do a default export
 * instead of named export.
 * 
 * Also, this file has too many unnecessary and commented code. So, better to
 * do some cleanup.
 */
// export default create(subscribeWithSelector((set) =>


export const useContinuumRobotStore = create(
  subscribeWithSelector((set) => {
    return {
      // blocksCount: 100,
      // Pages
      x: 7.5100,
      y: 0.000,
      z: 0.500,
      target: 15.0,

      setX: (val) => {
        set((state) => {
          return { x: val }
        })
      },

      setY: (val) => {
        set((state) => {
          return { y: val }
        })
      },

      setZ: (val) => {
        set((state) => {
          return { z: val }
        })
      },

      setTarget: (val) => {
        set((state) => {
          return { target: val }
        })
      },

    }
  })
)